.question {
  margin: 1rem 0;
  margin-left: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: justify;
}
.formBox {
  margin: 1rem 0;
}
.subFormBox {
  margin: 1rem 0;
  padding: 0 0 0 2rem;
}
i {
  font-size: 12;
}
.wrapper {
  padding: 2.6rem 4rem;
}
.wrapper2 {
  padding: 1rem;
}
.box {
  display: flex;
  align-items: center;
}
.answer {
  font-size: 1rem;
  margin-left: 0.2rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: #004aad;
}
.subq {
  margin-left: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: justify;
  margin-bottom: 0.2rem;
}
.suba {
  margin: 1rem 0;
  font-size: 1rem;
  margin-left: 0.2rem;
  line-height: 1.5rem;
  text-align: justify;
  font-weight: bold;
  color: #004aad;
}
.header {
  margin: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: justify;
  font-weight: bold;
  padding-top: 1rem;
}
.circel {
  font-size: 18px;
}
@media only screen and (max-width: 1024px) {
  .wrapper {
    padding: 0.5rem;
  }
  .wrapper2 {
    padding: 1rem;
  }
}
