@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

@font-face {
  font-family: "holiday";
  src: url(./assets/fonts/FontsFree-Net-HolidayFree.ttf);
  font-style: normal;
  font-weight: 100;
}

/* Dev styles */
/* * {
  outline: 1px dashed var(--bs-gray-400);
} */

html {
  height: 100%;
}
body {
  height: 100%;
}
h4 {
  font-weight: 600;
}
h5 {
  font-size: 18px !important;
  font-weight: 500 !important;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
footer {
  border-top: #f4f4ee 2px solid;
}
.scrollable-element {
  /* max-height: calc(100vh); */
  overflow-y: auto;
}
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--bs-gray-300);
  border-radius: 5px;
}
.inline-group>.form-check {
  display: inline-block;
  padding-right: 1rem;
}
.inline-group>.form-label {
  display: block;
}
#underwriterReplyFormTerms>.tab-content {
  border: solid 1px var(--bs-gray-300);
  border-top: none;
}
.disabled-text label {
  opacity: 0.5;
}
.disabled-text input {
  color: var(--bs-gray-500);
}

/* Advisor dashboard styling - \pages\advisorDashboard\AdvisorDashboard.js */
.dashboard-navbar {
  width: 14rem !important;
  background-color: #f4f4ee;
}
.toast {
  z-index: 1;
}

#mobile-viewport {
  display: none;
}

/* Loading spinner */
.loading-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-spinner div {
  animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.loading-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--bs-gray-500);
  margin: -4px 0 0 -4px;
}
.loading-spinner div:nth-child(1) {
  animation-delay: -0.036s;
}
.loading-spinner div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.loading-spinner div:nth-child(2) {
  animation-delay: -0.072s;
}
.loading-spinner div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.loading-spinner div:nth-child(3) {
  animation-delay: -0.108s;
}
.loading-spinner div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.loading-spinner div:nth-child(4) {
  animation-delay: -0.144s;
}
.loading-spinner div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.loading-spinner div:nth-child(5) {
  animation-delay: -0.18s;
}
.loading-spinner div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.loading-spinner div:nth-child(6) {
  animation-delay: -0.216s;
}
.loading-spinner div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.loading-spinner div:nth-child(7) {
  animation-delay: -0.252s;
}
.loading-spinner div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.loading-spinner div:nth-child(8) {
  animation-delay: -0.288s;
}
.loading-spinner div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* --------------------------------- */

@media screen and (max-width: 1100px) {
  #standard-viewport {
    display: none;
  }
  #mobile-viewport {
    display: initial;
  }
}


@media only screen and (max-width: 600px) {
  .move-up {
    position: fixed;
    margin-right: 10px;
    cursor: pointer;
    bottom: 40px;
    right: 0;
    z-index: 1;
    text-align: center;
    height: 45px;
    line-height: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}
