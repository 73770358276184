.assessments-data-container {
  transition: all;
  transition-duration: 250ms;
}

.assessments-data-row.collapsed .assessments-data-container {
  height: 2.4rem;
}

.assessments-data-row.expanded .assessments-data-container {
  height: 6rem;
}

.assessments-data-row.collapsed .expanded-data {
  display: none;
}

.assessments-data-row.expanded .expanded-data {
  display: flex;
}

.assessments-data-container .text-nowrap {
  text-overflow: ellipsis;
}

.assessments-data-row:hover {
  cursor: pointer;
}
