.company-logos {
  width: 100%;
  height: 4.5rem;
  object-fit: contain;
}
.modal-w90 {
  max-width: 90% !important;
}
.advisor-code-field:disabled {
  color: lightslategray;
}
